export const triggerEvent = (eventName, data = null, element = window) => {
  let ev = new CustomEvent(eventName, {
    detail: data,
    bubbles: true,
    cancelable: true,
  });

  element.dispatchEvent(ev);
};

/**
 * @param {Object} options - Parameter bag for the event options
 * @param {string} options.eventName - Name of the event
 * @param {function(*): *} options.eventHandler - What to do when the event is called
 * @param {EventTarget} [options.element=window] - on which element the eventHandler should be attached
 * @param {boolean} [options.once=false] - should the event only be listened to once?
 * @param {boolean} [options.passive] - should the event be passive (e.g. for scroll)?
 */
const createEventHandler = ({
  eventName,
  eventHandler,
  element = window,
  once = false,
  passive,
}) => {
  const options = { once };
  if (typeof passive !== 'undefined') {
    options.passive = passive;
  }

  const wrappedHandler = (e) => eventHandler.call(element, e);
  element.addEventListener(eventName, wrappedHandler, options);
  return wrappedHandler;
};

/**
 * @param {Object} options - Parameter bag for the event options
 * @param {string|array} options.eventName - Name(s) of the event, as a single string or an array of strings
 * @param {function(*): *} options.eventHandler - What to do when the event is called
 * @param {EventTarget} [options.element=window] - on which element the eventHandler should be attached
 * @param {boolean} [options.once=false] - should the event only be listened to once?
 * @param {boolean} [options.passive] - should the event be passive (e.g. for scroll)?
 * @return {function|array} returns the wrapped handler or an array of wrapped handlers
 */
export const onEvent = (options) => {
  // support multiple events
  if (Array.isArray(options.eventName)) {
    return options.eventName.map((eventNamePart) => {
      const newObject = Object.assign({}, options, {
        eventName: eventNamePart,
      });
      return createEventHandler(newObject);
    });
  } else {
    return createEventHandler(options);
  }
};

/**
 * This event is triggered when user submits a poll/rating. It is an indicator to stop media players (if running).
 *
 * @type {string}
 */
export const POLL_SUBMITTED = 'srf.poll.submitted';

/**
 * We trigger those events to indicate that a poll error (pj, wrong input, etc...) occurred.
 * With the event we send data to indicate what was wrong and what we would like to happen.
 *
 * @type {string}
 */
export const POLL_ERROR = 'srf.poll.error';

/**
 * This event is triggered when a component needs an interaction to be tracked.
 *
 * @type {string}
 */
export const TRACK_INTERACTION = 'srf.track.interaction';

/**
 * Still used by SRF News Graphics Team
 * @deprecated
 */
export const LEGACY_TRACK_INTERACTION = 'fef.track.interaction';

/**
 * This event is triggered when a component needs an action to be tracked by UDP.
 *
 * @type {string}
 */
export const TRACK_UDP_ACTION = 'srf.track.udp.action';

export const EXPANDABLE_INTERACTION = 'srf.expandable.interaction';

/**
 * When a Video Modal is closed, all video teasers have to be informed about this event.
 *
 * @type {string}
 */
export const VIDEO_MODAL_CLOSED = 'srf.videomodal.closed';

/**
 * This event is triggered when a video teaser is clicked.
 *
 * @type {string}
 */
export const TEASER_VIDEO_CLICK = 'srf.teaser.video.clicked';

/**
 * This event is triggered when a audio teaser is clicked.
 *
 * Used without import in radioEpisode.html.twig - do not remove!
 * @type {string}
 */
export const TEASER_AUDIO_CLICK = 'srf.teaser.audio.clicked';

/**
 * This event is triggered when a previously hidden teaser is being displayed.
 *
 * Used without import in experiments.html.twig - do not remove!
 * @type {string}
 */
export const SHOW_HIDDEN_TEASER = 'srf.teaser.show';

/**
 * Indicates that all styles are loaded.
 *
 * Used without import in Layout/layout.html.twig - do not remove!
 * @type {string}
 */
export const STYLES_LOADED = 'srf.styles.loaded';

/**
 * Triggered when a video should start playing due to autoplay.
 * @type {string}
 */
export const VIDEO_AUTOPLAY_START = 'srf.teaser.video.autoplay.start';

/**
 * Triggered when a video started by autoplay should stop.
 * @type {string}
 */
export const VIDEO_AUTOPLAY_STOP = 'srf.teaser.video.autoplay.stop';

/**
 * Triggered when a swipeable collection gets new content (more or less teasers)
 * @type {string}
 */
export const SWIPEABLE_CONTENT_CHANGED = 'srf.swipeable.content-changed';

/**
 * Use this event if you want to manually show a tooltip (if defined) on an element
 * @type {string}
 */
export const TOOLTIP_SHOW = 'srf.tooltip.show';

/**
 * Use this event if you want to manually hide a tooltip (if defined) on an element
 * @type {string}
 */
export const TOOLTIP_HIDE = 'srf.tooltip.hide';

/**
 * Use this event if you want to know which search results are found.
 * @type {string}
 */
export const ALGOLIA_SEARCH_RESULTS = 'srf-search-results';

/**
 * This event is triggered when a textarea changes its validation status.
 *
 * Example trigger: textarea
 * Example listener: comment form (for the button which is enabled or disabled)
 *
 * @type {string}
 */
export const TEXTAREA_VALIDATED = 'srf.textarea.validated';

export const MEDIA_READY = 'srf.media.ready';
export const MEDIA_FULLSCREEN_CHANGE = 'srf.media.fullscreenchange';
export const MEDIA_START = 'srf.media.start';
export const MEDIA_CAN_PLAY = 'srf.media.canplay';
export const MEDIA_CLICKED = 'srf.media.clicked';
export const MEDIA_ENDED = 'srf.media.ended';
export const MEDIA_ERROR = 'srf.media.error';
export const MEDIA_PAUSED = 'srf.media.paused';
export const MEDIA_PLAYING = 'srf.media.playing';
export const MEDIA_TIME_UPDATE = 'srf.media.timeupdate';
export const MEDIA_VOLUME_CHANGED = 'srf.media.volumechanged';

export const SUBNAV_CLOSE = 'srf.subnav.close';

/**
 * This event is triggered when the DOM was changed.
 *
 * Example trigger: FefDomObserver
 * Example listener: Modal (checks if new modal triggers were added to the DOM)
 *
 * @type {string}
 */
export const DOM_CHANGED_EVENT = 'srf.dom.changed';

/**
 * This event is triggered when the DOM is initialized.
 *
 * Example trigger: FefDomObserver
 * Example listener: Modal
 *
 * @type {string}
 */
export const DOM_INIT_EVENT = 'srf.dom.initialized';

/**
 * Triggered when a search component should be deactivated.
 *
 * Example trigger: A2zFilter (CMS)
 * Example listener: SrfSearch
 *
 * @type {string}
 */
export const SET_SEARCH_INACTIVE_EVENT = 'srf.search.deactivate';

/**
 * Triggered when a modal got closed.
 *
 * Example trigger: modal (CMS)
 * Example listener: SurveyGizmo.js
 *
 * @type {string}
 */
export const MODAL_CLOSED = 'srf.modal.closed';

/**
 * Triggered on page when a teaser was clicked/tapped by user
 * (Can be used to track seen items or to re-render new state)
 */
export const TEASER_CLICKED = 'srf.teaser.clicked';

/**
 * Triggered when the theme-color meta-tag has to change its value.
 *
 * @type {string}
 */
export const SET_META_THEME_COLOR = 'srf.metathemecolor.set';

/**
 * Triggered when a modal should be opened.
 * Make sure you send { modalId: 'XXX' } so that the correct modal is opened.
 *
 * @type {string}
 */
export const OPEN_MODAL = 'srf.modal.open';

/**
 * Triggered when a modal should be closed.
 * Make sure you send { modalId: 'XXX' } so that the correct modal is closed.
 *
 * @type {string}
 */
export const CLOSE_MODAL = 'srf.modal.close';

export const SCROLL_RIGHT_EVENT = 'srf.swipeableArea.scroll-right';
export const SCROLL_LEFT_EVENT = 'srf.swipeableArea.scroll-left';
export const SCROLL_TO_EVENT = 'srf.swipeableArea.scroll-to';

export const REINIT_SWIPEABLE = 'srf.swipeableArea.reinitialize';

export const ACTIVATE_TRACKER = 'srf.tracker.activate';
export const DISABLE_TRACKER = 'srf.tracker.disable';
export const ACTIVATE_CMP_CATEGORY = 'srf.cmp-category.activate';

export const CMP_PRIVACY_SHIELD_LOADED = 'srf.privacyshield.loaded';

/**
 * @type {string}
 *
 * Used without import in janrain-init.js - do not remove!
 */
export const FOCUS_MOVE = 'flyingfocus:move';

export const FOCUS_MOVE_FORCE = 'flyingfocus:move:force';

export const JUMP_TO_ANCHOR = 'srf:anchor';
export const APP_ID_RECEIVED = 'srf:appId:received';

/**
 * @type {string}
 *
 * Used to pass the user location from the app via the native bridge to meteo widgets
 */
export const SET_USER_LOCATION = 'SetUserLocation';

/**
 * @type {string}
 *
 * Used to indicate that the user has navigated inside an image gallery while it was opened fullscreen in the app
 */
export const SET_IMAGE_GALLERY_INDEX = 'srf.imageGallery.index';

import React from 'react';
import classNames from 'classnames';
import './image.scss';

// These types and the variations they infer are copied from Sizes.php.
// Adjust with care.
export const IMAGE_TYPES = {
  ARTICLE_BODY_FULLWIDTH: 'article_body_fullwidth',
  ARTICLE_BODY_SMALL: 'article_body_small',
  ARTICLE_VIDEO_GALLERY: 'article_video_gallery',
  RLP_SIZE_S: 'size-s',
  RLP_SIZE_S_M: 'size-s',
  RLP_SIZE_SQUARE: 'size-square',
  RADIO_SIZE_SQUARE: 'size-square-medium',
  RADIO_SIZE_SQUARE_LARGE: 'size-square-large',
  RLP_SIZE_M: 'size-m',
  RLP_SIZE_M_BUT_S_ON_MOBILE: 'size-m',
  RLP_SIZE_L: 'size-l',
  RLP_SIZE_XL: 'size-xl',
  RLP_LONGFORM: 'longform',
  RLP_LONGFORM_XL: 'longform-xl',
  RLP_GIF: 'gif',
};

const DEFAULT_VARIATIONS = {
  '160w': '160ws',
  '320w': '320ws',
  '480w': '480ws',
  '640w': '640ws',
  '960w': '960ws',
  '1280w': '1280ws',
};

const FREE_RATIO_VARIATIONS = {
  '160w': '160w',
  '320w': '320w',
  '480w': '480w',
  '640w': '640w',
  '960w': '960w',
  '1280w': '1280w',
};

const WIDESCREEN_VARIATIONS = {
  '160w': '160ws',
  '320w': '320ws',
  '480w': '480ws',
  '640w': '640ws',
  '960w': '960ws',
  '1280w': '1280ws',
};

const SQUARE_VARIATIONS = {
  '160w': 'square_160w',
  '240w': 'square_240w',
};

const SQUARE_VARIATIONS_BIG = {
  '320w': 'square_small',
  '640w': 'square_medium',
  '800w': 'square_large',
};

/**
 * @param variations {Object}
 * @param type {string}
 * @returns {string}
 */
export const getImageSrcset = (variations = {}, type) => {
  let variationsToRender = {};

  switch (type) {
    case IMAGE_TYPES.RLP_SIZE_S:
      variationsToRender = SQUARE_VARIATIONS;
      break;
    case IMAGE_TYPES.RLP_SIZE_S_M:
    case IMAGE_TYPES.RLP_SIZE_SQUARE:
    case IMAGE_TYPES.RADIO_SIZE_SQUARE:
    case IMAGE_TYPES.RADIO_SIZE_SQUARE_LARGE:
      variationsToRender = Object.assign(
        {},
        SQUARE_VARIATIONS,
        SQUARE_VARIATIONS_BIG,
      );
      break;
    case IMAGE_TYPES.RLP_SIZE_M_BUT_S_ON_MOBILE:
      variationsToRender = Object.assign(
        {},
        SQUARE_VARIATIONS,
        WIDESCREEN_VARIATIONS,
      );
      break;
    case IMAGE_TYPES.RLP_LONGFORM:
      variationsToRender = SQUARE_VARIATIONS_BIG;
      break;
    case IMAGE_TYPES.RLP_LONGFORM_XL:
      variationsToRender = WIDESCREEN_VARIATIONS;
      break;
    case IMAGE_TYPES.ARTICLE_BODY_FULLWIDTH:
      variationsToRender = FREE_RATIO_VARIATIONS;
      break;
    default:
      variationsToRender = DEFAULT_VARIATIONS;
  }
  return Object.keys(variationsToRender)
    .map((width) => {
      const value = variationsToRender[width];
      if (variations[value]) {
        return variations[value] + ' ' + width;
      } else {
        return null;
      }
    })
    .join(', ');
};

/**
 * @param type {string}
 * @returns {string}
 */
export const getImageSrcsetSizes = (type) => {
  let sizes = '';

  switch (type) {
    case IMAGE_TYPES.ARTICLE_BODY_FULLWIDTH:
      sizes = '(min-width: 790px) 790px, 100vw';
      break;
    case IMAGE_TYPES.ARTICLE_BODY_SMALL:
      sizes = '196px';
      break;
    case IMAGE_TYPES.ARTICLE_VIDEO_GALLERY:
      sizes =
        '(max-width: 719px) 640px, (min-width: 1024px) 288px, (min-width: 720px) 320px, 640px';
      break;
    case IMAGE_TYPES.RLP_SIZE_S:
      sizes = '(max-width: 719px) 80px, 96px';
      break;
    case IMAGE_TYPES.RLP_SIZE_SQUARE:
      sizes = '(max-width: 719px) 45vw, 17vw';
      break;
    case IMAGE_TYPES.RADIO_SIZE_SQUARE:
      sizes = '(max-width: 719px) 80px, 168px';
      break;
    case IMAGE_TYPES.RADIO_SIZE_SQUARE_LARGE:
      sizes = '(max-width: 719px) 221px, 272px';
      break;
    case IMAGE_TYPES.RLP_SIZE_S_M:
      sizes = '(max-width: 719px) 80px, 112px';
      break;
    case IMAGE_TYPES.RLP_LONGFORM:
    case IMAGE_TYPES.RLP_SIZE_M:
      sizes = '(max-width: 719px) 100vw, 33.4vw';
      break;
    case IMAGE_TYPES.RLP_LONGFORM_XL:
      sizes = '100vw';
      break;
    case IMAGE_TYPES.RLP_SIZE_M_BUT_S_ON_MOBILE:
      sizes = '(max-width: 719px) 80px, 33.4vw';
      break;
    case IMAGE_TYPES.RLP_SIZE_L:
      sizes = '(max-width: 719px) 100vw, 50vw';
      break;
    case IMAGE_TYPES.RLP_SIZE_XL:
      sizes = '(max-width: 719px) 100vw, 66vw';
      break;
    default:
      sizes = '100vw';
  }

  return sizes;
};

/**
 * @param variations {Object}
 * @param type {string}
 * @returns {string}
 */
export const getImageSrc = (variations = {}, type) => {
  let srcSize = '';

  switch (type) {
    case IMAGE_TYPES.RLP_SIZE_S:
    case IMAGE_TYPES.RLP_SIZE_SQUARE:
      srcSize = '160ws';
      break;
    case IMAGE_TYPES.RLP_SIZE_M:
    case IMAGE_TYPES.RLP_SIZE_M_BUT_S_ON_MOBILE:
      srcSize = '480ws';
      break;
    case IMAGE_TYPES.RLP_SIZE_L:
      srcSize = '640ws';
      break;
    case IMAGE_TYPES.RLP_LONGFORM_XL:
    case IMAGE_TYPES.RLP_SIZE_XL:
      srcSize = '960ws';
      break;
    case IMAGE_TYPES.RLP_SIZE_S_M:
    case IMAGE_TYPES.RADIO_SIZE_SQUARE:
    case IMAGE_TYPES.RADIO_SIZE_SQUARE_LARGE:
      srcSize = '320ws';
      break;
    case IMAGE_TYPES.RLP_LONGFORM:
      srcSize = 'square_medium';
      break;
    case IMAGE_TYPES.RLP_GIF:
      srcSize = '480w';
      break;
    case IMAGE_TYPES.ARTICLE_BODY_FULLWIDTH:
      srcSize = '960w';
      break;
    default:
      srcSize = '640w';
  }

  return variations[srcSize] || '';
};

const Image = ({ image, type, styleModifier = '' }) => {
  const imgAttributes = {};

  if (image.variations) {
    imgAttributes.srcSet = getImageSrcset(image.variations, type);
    imgAttributes.sizes = getImageSrcsetSizes(type);
    imgAttributes.src = getImageSrc(image.variations, type);
  }

  if (image.src) {
    imgAttributes.src = image.src;
  }

  if (image.width) {
    imgAttributes.width = image.width;
  }

  if (image.heigth) {
    imgAttributes.heigth = image.heigth;
  }

  if (image.provider === 'rokka') {
    imgAttributes.src = image.defaultSource;
  }

  return (
    <div className={classNames('image', styleModifier)}>
      <img
        className="image-figure__image"
        title={image.title ?? ''}
        alt={image.alttext ?? ''}
        {...imgAttributes}
      />
    </div>
  );
};

export default Image;

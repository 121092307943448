export default class ClientStorage {
  /**
   * Returns a value if local storage is available and item is set.
   * If optionalDefaultValue is given, this is returned if local storage is not available or no item is set
   *
   * @param key
   * @param optionalDefaultValue
   * @returns {*}
   */
  static getItem(key, optionalDefaultValue) {
    if (!this.isLocalStorageAvailable() && optionalDefaultValue) {
      return optionalDefaultValue;
    }

    const item = localStorage.getItem(key);

    if (!item && optionalDefaultValue) {
      return optionalDefaultValue;
    }

    return item;
  }

  /**
   * Returns (safely) json parsed content of a given key.
   * If the key is not set, the optional default value is returned. When no default value is provided the
   * default value is set to a simple JavaScript object.
   *
   * @param key
   * @param optionalDefaultValue
   * @returns {*}
   */
  static getItemJsonParsed(key, optionalDefaultValue = {}) {
    if (!this.hasItem(key)) {
      return optionalDefaultValue;
    }

    try {
      return JSON.parse(
        this.getItem(key, JSON.stringify(optionalDefaultValue)),
      );
    } catch (e) {
      return optionalDefaultValue;
    }
  }

  /**
   * Helper method, since it's used very frequently in our code
   *
   * @param key
   * @param item
   */
  static setItemJsonStringified(key, item) {
    this.setItem(key, JSON.stringify(item));
  }

  /**
   * Sets an item in localStorage.
   * Returns false if item could not be added, true if successfully added.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  static setItem(key, value) {
    if (!this.isLocalStorageAvailable()) {
      return false;
    }

    localStorage.setItem(key, value);
    return true;
  }

  /**
   * Safe method to check for a set item in local storage
   *
   * @param key
   * @returns {boolean}
   */
  static hasItem(key) {
    if (!this.isLocalStorageAvailable()) {
      return false;
    }

    return !!localStorage.getItem(key);
  }

  /**
   * Removes an item from localStorage.
   * Returns false if item could not be removed, true if successfully removed.
   *
   * @param key
   * @returns {boolean}
   */
  static removeItem(key) {
    if (!this.isLocalStorageAvailable() || !this.hasItem(key)) {
      return false;
    }

    localStorage.removeItem(key);
    return true;
  }

  /**
   * @param key
   * @param id
   * @param maxItems
   */
  static prependIdToList(key, id, maxItems = 100) {
    this.removeIdFromList(key, id);

    let listItems = this.getItemJsonParsed(key, []);
    if (typeof id !== 'string') {
      return;
    }

    // let the method fix wrong data types
    if (!Array.isArray(listItems)) {
      listItems = [];
    }

    listItems.unshift(id);

    if (listItems.length > maxItems) {
      listItems
        .reverse()
        .slice(listItems.length - maxItems)
        .reverse();
    }

    this.setItemJsonStringified(key, listItems);
  }

  /**
   * @param key
   * @param id
   */
  static removeIdFromList(key, id) {
    if (typeof id !== 'string') {
      return;
    }

    let listItems = this.getItemJsonParsed(key, []);

    // Clean up corrupt data types
    if (!Array.isArray(listItems)) {
      listItems = [];
    }

    const newItems = listItems.filter((listItem) => {
      return listItem !== id;
    });
    this.setItemJsonStringified(key, newItems);
  }

  /**
   * Most easy way to check if the localStorage is working.
   *
   * @returns {boolean}
   */
  static isLocalStorageAvailable() {
    try {
      localStorage.getItem('fefLocalStorage');
      return true;
    } catch {
      return false;
    }
  }
}

export class SessionStorage {
  static getItem(key, optionalValue) {
    if (typeof Storage !== 'undefined' && sessionStorage?.[key]) {
      return sessionStorage[key];
    }
    return optionalValue;
  }
}

import { STOP_ICON_CLASS } from '@fec/frontend/modules/content/medium/medium';

export const PLACEHOLDER_SIZE = '#JS-PLACEHOLDER-SIZE#';
export const PLACEHOLDER_POS = '#JS-PLACEHOLDER-POS#';

export const PLAYING_CLASS = 'teaser-ng--active-media js-teaser-playing';

const PLAYING_CLASSES = PLAYING_CLASS.split(' ');
const STOP_ICON_CLASSES = STOP_ICON_CLASS.split(' ');

export const isTeaserPlaying = (element) =>
  element.classList.contains('js-teaser-playing');

export const markTeaserAsPlaying = (element) => {
  element.classList.add(...PLAYING_CLASSES);
  element.querySelector('.play-icon')?.classList.add(...STOP_ICON_CLASSES);
};

export const markTeaserAsNotPlaying = (element) => {
  element.classList.remove(...PLAYING_CLASSES);
  element.querySelector('.play-icon')?.classList.remove(...STOP_ICON_CLASSES);
};

export const GET_TEASER_SIZE_FROM_CSS = (element) =>
  window
    .getComputedStyle(element, '::before')
    .getPropertyValue('content')
    .replace(/"/g, '')
    .replace('type-', 'size-');

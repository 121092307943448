export function readArticleIdFromMetaTag() {
  return readMetaTag('srf:content:id');
}

export function readUrnFromMetaTag() {
  return readMetaTag('srf:urn');
}

export function readCommentsApiEndpoint() {
  return readMetaTag('srf:comments:endpoint');
}

export function readSubscriptionList() {
  if (hasMetaTag('srf:subscription:list')) {
    const list = readMetaTag('srf:subscription:list');
    if (list) {
      return list.split(',');
    }
  }
  return [];
}

export function readMetaTagWithDefault(identifier, fallback) {
  if (hasMetaTag(identifier)) {
    return readMetaTag(identifier);
  } else {
    return fallback;
  }
}

const readMetaTag = (identifier) => {
  return $(`meta[name="${identifier}"]`).attr('content');
};

const hasMetaTag = (identifier) => {
  const tag = $(`meta[name="${identifier}"]`);
  return tag.length && tag.length > 0;
};

import React from 'react';
import classNames from 'classnames';
import Image, { IMAGE_TYPES } from '@fec/frontend/elements/media/image/image';
import MediaCaption from '@fec/frontend/elements/media/media-caption/media-caption';
import './medium.scss';

export const STOP_ICON_CLASS = 'play-icon--stop play-icon--ng-stop';

export const isMediumPlaying = ($element) => {
  return $element.find('.medium').hasClass('js-medium-is-active');
};

export const markMediumAsPlaying = ($element) => {
  $element.find('.medium').addClass('medium--active js-medium-is-active');
  $element.find('.media-caption').addClass('media-caption--active');
  $element
    .find('.media-still__image')
    .addClass('media-still__image--webview-active');
  $element.find('.play-icon').addClass(STOP_ICON_CLASS);
};

export const markMediumAsStopped = ($element) => {
  $element.find('.medium').removeClass('medium--active js-medium-is-active');
  $element.find('.media-caption').removeClass('media-caption--active');
  $element
    .find('.media-still__image')
    .removeClass('media-still__image--webview-active');
  $element.find('.play-icon').removeClass(STOP_ICON_CLASS);
};

const Medium = ({
  mediumType,
  image = null,
  caption = null,
  styleModifier = '',
  imageModifier = '',
  mediaCaptionModifier = '',
}) => (
  <div className={classNames('medium', styleModifier)}>
    <div className="medium__object">
      {mediumType === 'image' && image && (
        <Image
          image={image}
          type={IMAGE_TYPES.ARTICLE_BODY_FULLWIDTH}
          styleModifier={imageModifier}
        />
      )}
    </div>

    {caption && (
      <MediaCaption caption={caption} styleModifier={mediaCaptionModifier} />
    )}
  </div>
);

export default Medium;

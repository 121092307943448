import React from 'react';
import classNames from 'classnames';
import { Trans } from '@fec/assets/js/utils/trans';
import './media-caption.scss';

const MediaCaption = ({ caption, styleModifier, children }) => {
  const translator = new Trans();

  return (
    <div className={classNames('media-caption', styleModifier)}>
      {children ? (
        children
      ) : (
        <>
          <span className="h-offscreen">{translator.getGlobal('Legende')}</span>

          {caption.title && (
            <span className="media-caption__title" data-app-image-title="">
              {caption.title}
            </span>
          )}

          {caption.legend && (
            <span
              className="media-caption__description"
              data-app-image-title="">
              {caption.legend}
            </span>
          )}

          {caption.source && (
            <>
              {caption.sourceLink ? (
                <a
                  href={caption.sourceLink}
                  className="media-caption__source-link"
                  rel="nofollow noreferrer"
                  target="_blank"
                  data-app-image-source="">
                  {caption.source}
                </a>
              ) : (
                <span
                  className="media-caption__source"
                  data-app-image-source="">
                  {caption.source}
                </span>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MediaCaption;

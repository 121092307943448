let globalTranslations = {};
export function Trans(optComponentSelector) {
  this.getWeekDays = function () {
    return globalTranslations.get('i18n:date:weekdays').split(',');
  };

  this.getMonths = function () {
    return globalTranslations.get('i18n:date:months').split(',');
  };

  this.accessTransOnElement = function (name, optSelector) {
    let selector = optSelector || optComponentSelector || 'body';
    return $(selector).data('i18n-text-' + name) || '';
  };

  this.getGlobal = function (key) {
    return globalTranslations.has(key) ? globalTranslations.get(key) : '';
  };

  this.getAll = function (optSelector) {
    let returnValue = {},
      selector = optSelector || optComponentSelector || 'body',
      all = $(selector).data();

    if (typeof all === 'object') {
      returnValue = Object.keys(all)
        .filter((key) => key.indexOf('i18nText') === 0)
        .reduce((obj, key) => {
          // Cut i18n prefix
          obj[key.slice(8).charAt(0).toLowerCase() + key.slice(9)] = all[key];
          return obj;
        }, {});
    }

    return returnValue;
  };

  /**
   * Retrieve a translation string by the provided key. Then, replace a
   * placeholder in a translation string with the provided value.
   * The placeholder is defined as "#JS-PLACEHOLDER#". The translation strings
   * are rendered as part of the DOM via twig, so that they can be translated
   * via CrowdSource.
   *
   * Example translation string: 'Vor #JS-PLACEHOLDER# Sekunden'
   *
   * @param key {string}, e.g.
   * @param value {number}
   * @returns {string} translation with replaced placeholder
   */
  this.translatedWithPlaceHolder = function (key, value) {
    return this.getGlobal(key).replace('#JS-PLACEHOLDER#', value);
  };
}

export function init() {
  globalTranslations = new Map(
    $('meta[name^="i18n:"]')
      .toArray()
      .map((item) => [$(item).prop('name'), $(item).prop('content')]),
  );
}

import moment from 'moment';

export class Tools {
  constructor() {}

  setData(key, value) {
    if (typeof localStorage === 'object') {
      try {
        localStorage.setItem(key, value);
      } catch (e) {
        //oh no
      }
    }
  }

  getData(key) {
    return typeof localStorage === 'object' ? localStorage.getItem(key) : null;
  }

  removeData(key) {
    typeof localStorage === 'object' ? localStorage.removeItem(key) : null;
  }

  getDaysSincePublished(publicationDateTime) {
    let result = null;
    if (moment(publicationDateTime).isValid()) {
      let publicationDate = moment(publicationDateTime);
      result = String(-1 * publicationDate.diff(moment.now(), 'days'));
    }
    return result;
  }

  async loadExternalJs(src) {
    return new Promise((resolve, reject) => {
      let element = document.createElement('script');
      element.setAttribute('language', 'javascript');
      element.setAttribute('type', 'text/javascript');
      element.setAttribute('src', src);
      document.body.appendChild(element);

      element.onload = () => {
        resolve('Success!');
      };

      element.onerror = function () {
        reject(src);
      };
    });
  }

  getModData(modData, key) {
    let result = 'undefined';
    if (modData.hasOwnProperty('srg_' + key)) {
      result = modData['srg_' + key];
    } else if (modData.hasOwnProperty('srf_' + key)) {
      result = modData['srf_' + key];
    }

    if (modData.hasOwnProperty('srg_override_' + key)) {
      result = modData['srg_override_' + key];
    }

    return this.decodeModParams(result);
  }

  decodeModParams(data) {
    return decodeURIComponent(decodeURIComponent(data)).replace('+', ' ');
  }

  injectReferrerInfo(data) {
    this.setData('srf:analytics:mod', JSON.stringify(data));
    this.setData('srf:analytics:mod:app', JSON.stringify(data));
  }
}

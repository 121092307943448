/* global UC_UI */
import {
  ACTIVATE_CMP_CATEGORY,
  ACTIVATE_TRACKER,
  DISABLE_TRACKER,
  triggerEvent,
} from '@fec/assets/js/utils/event';

const TRACKERS = [
  {
    id: 'kameleeon',
    ucId: '9FYjH37-J',
  },
  {
    id: 'SurveyGizmo',
    ucId: 'Wom6s1CtF',
  },
  {
    id: 'airship',
    ucId: 'jcJkRKyXPGxJTX',
  },
];

const enabledCategories = new Map();

export function init() {
  //activate trackers if consent changed
  window.addEventListener('ucEvent', function (e) {
    if (isConsentProviderReady()) {
      // as usercentrics handles explicit consent asynchronously but in a weird way,
      // we need to skip the explicit consent check here
      // as UC_UI.isConsentRequired() is true only after this event is handled
      if (e.detail.event === 'consent_status' && e.detail.type === 'explicit') {
        handleCmpDecisions(true);
      } else {
        handleCmpDecisions();
      }
    }
  });

  // activate trackers if UC UI is already initialized
  if (isConsentProviderReady()) {
    handleCmpDecisions();
  } else {
    //activate trackers if UC UI is loaded and therefore ready
    window.addEventListener('UC_UI_INITIALIZED', function () {
      handleCmpDecisions();
    });
  }

  // open cmp settings when clicking on the link in the footer
  Array.from(document.getElementsByClassName('js-cmp-settings')).forEach(
    (anchor) =>
      anchor.addEventListener('click', (e) => {
        e.preventDefault();
        UC_UI.showSecondLayer();
      }),
  );
}

const handleCmpDecisions = (skipConsentCheck = false) => {
  if (UC_UI.isConsentRequired() && skipConsentCheck === false) {
    return false;
  }

  //activate trackers
  TRACKERS.forEach((tracker) => {
    if (hasTrackerConsent(tracker)) {
      activateTracker(tracker.id);
    } else {
      disableTracker(tracker.id);
    }
  });

  //activate categories
  const categories = [
    ...new Set(
      UC_UI.getServicesBaseInfo().map((service) => service.categorySlug),
    ),
  ];
  categories.forEach((category) => {
    if (hasCategoryConsent(category)) {
      activateCategory(category);
    }
  });
};

const isConsentProviderReady = () => {
  return window.UC_UI && window.UC_UI.isInitialized();
};

export const hasCategoryConsent = (category) => {
  if (enabledCategories.has(category)) {
    return enabledCategories.get(category);
  }

  if (!isConsentProviderReady()) {
    return false;
  }

  const servicesInCategoryWithoutConsent = UC_UI.getServicesBaseInfo()
    .filter((service) => service.categorySlug === category)
    .filter((service) => service.consent.status === false);

  return servicesInCategoryWithoutConsent.length === 0;
};

/**
 * Queries UC consent info to find out if consent for service has been given
 * @param tracker
 * @returns {boolean}
 */
const hasTrackerConsent = (tracker) => {
  const consentBasicInfos = UC_UI.getServicesBaseInfo();
  const trackerDetails = consentBasicInfos.find((i) => i.id === tracker.ucId);
  if (UC_UI.isConsentRequired()) {
    return trackerDetails?.isEssential === true;
  }

  if (trackerDetails?.hasOwnProperty('consent')) {
    return trackerDetails.consent.status === true;
  }
  return false;
};

export const activateTracker = (id) => {
  triggerEvent(ACTIVATE_TRACKER, {
    trackerId: id,
  });
};

export const disableTracker = (id) => {
  triggerEvent(DISABLE_TRACKER, {
    trackerId: id,
  });
};

export const activateAllTrackers = () => {
  TRACKERS.forEach((tracker) => {
    activateTracker(tracker.id);
  });
};

export const activateCategory = (slug) => {
  enabledCategories.set(slug, true);
  triggerEvent(ACTIVATE_CMP_CATEGORY, {
    category: slug,
  });
};
